<template>

    <div class="table-row">
        <div class="table-th">Номер заказа</div>
        <div class="table-th">Машинное время ({{sett.bottleneck_capacity_min}} мин.)</div>
    </div>
    <div v-if="currOrders.length">
      <div :class="{'odd' : index%2 == 0}" class="table-row"
           v-for="(order, index) in currOrders" :key="index">
        <div class="table-tr">{{order.number}}</div>
        <div class="table-tr">{{order.timeMachine}}</div>
      </div>
      <div class="table-row">
        <div class="table-tr">Лимит на текущий день:</div>
        <div class="table-tr"> {{sumTime}}/{{sett.bottleneck_capacity_min}}</div>
      </div>
    </div>
    <template v-else>
      <div class="table-row dang">
        На текущий день заказы еще не назначались
      </div>
    </template>
    <div class="wrp-form w500 table-th">
      <select v-model="order.selected">
        <option :value="-1" :key="-1">--Выберите заказа--</option>
        <option v-for="(option, index) in order.options"
                :value="index" :key="index">{{option.text}}</option>
      </select>
      <a v-if="order.selected !== '-1'" @click="addOrder" class="btn-add-list">Добавить заказ</a>
      <span v-else class="btn-add-list no-border">Добавить заказ</span>
    </div>

    <br />
    <br />

    <div class="list">
        <div v-for="(one,index) in list" :key="index" >
          {{index+1}}. {{one}}
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Planing',
  data() {
    return {
      order: {
        selected: '-1',
        options: [],
      },
      ert: '',
      sett: {
        bottleneck_capacity_min: 0,
      },
      currOrders: [],
      list: [
        'Берем очередной заказ из списка незапущенных в производство, но имеющих статус готовности к запуску ("технологическая операция")',
        'Проверяем, нужено ли узкое место для выполнения заказа (перебираем все операции заказа и сравниваем с = ПАРАМЕТРЫ!E8)',
        'Если нет, то ставим заказ на выполнение, рассчитываем для него буфер, вычисляем дату сдачи = сегодня+ длина буфера',
        'Если да, считаем сумму машинного времени и сравнимваем с объемом суточного заказа.',
        'Как только сумма превысит объем суточного заказа, останавливаем постановку заказов в производство.',
        'В Asystem надо вернуть параметр заказа, означающий, что заказ запущен',
        'Нужна кнопка обмена информаций с Asystem (и циклическое выполнение скрипта)',
      ],
      errors: [],
    };
  },
  computed: {
    sumTime() {
      let sum = 0;
      if (this.currOrders.length > 0) {
        this.currOrders.forEach((elem) => {
          sum += parseInt(elem.timeMachine, 10);
        });
      }
      return sum;
    },
  },
  mounted() {
    this.getReadyOrder();
    this.infoForPlanning();
  },
  methods: {
    getReadyOrder() {
      axios.get('api/orders-ready')
        .then((res) => {
          this.order.options = res.data.options;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    infoForPlanning() {
      axios.get('api/info-for-planning')
        .then((res) => {
          this.sett.bottleneck_capacity_min = res.data.bottleneck_capacity_min;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    addOrder() {
      if (this.sumTime < this.sett.bottleneck_capacity_min) {
        this.currOrders.push(this.order.options[this.order.selected]);
        this.order.options.splice(this.order.selected, 1);
        this.order.selected = '-1';
      } else {
        alert('Лимит заказов на день превышен');
      }
    },
  },
};
</script>

<style scoped>
  .container-sys{padding:20px;}

  .table-row{display: flex; flex-direction: row; width:500px; color:#fff;}
  .table-th, .table-tr{flex:1; padding:10px;}
  .table-th{background:#3e5977; font-size:14px; font-weight:bold;}

  .table-tr{background: #5b7898; border-bottom: 1px solid #c8c8c8;}
  .odd .table-tr{background:#7191b4;}

  .dang{background:#ffe5e5; color:#9d3939; padding:10px;}

  .wrp-form.w500{width:500px}

  select{background: #fff;
    border: none;
    padding: 12px 8px;
    border-radius: 4px;
  cursor:pointer;
  width:60%}

  .btn-add-list{ background: #5781d2; cursor:pointer; float:right;
    padding: 10px 18px;
    color: #fff;
    font-size: 14px;
    display:block;
    width: 170px;
    text-align: center;
    border-radius:4px;
    border:1px solid #dde7f3; transition:all 1s;}
  .btn-add-list:hover{opacity:0.9}

  .no-border{border:1px solid #5781d2}
</style>
